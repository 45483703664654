// libraries
import React from "react";

// about
import constants from "../constants/data";


const About = () => {
    return (
        <div className="about">
            <div className="about-image-wrapper">
                <img src="../images/jenny.png" title="Jenny Neal-Teekasingh" />
            </div>
            <div>
                <p>
                    Jenny Neal-Teekasingh author of <nobr><a href={constants.blurb} target="_blank">The Emblem of the Sea</a></nobr>, invites you on a journey to the bottom of the ocean. Fall in love with the characters of the mesmerizing sapphic underwater retelling of Romeo &amp; Juliet.
                </p>
                <p>Let tales of magic and forbidden love wisk you away to places unknown. Follow Jenny through her tales of reincarnation and fated love.</p>
            </div>
        </div>
    );
};

export default About;