// libraries
import React from "react";

// data
import constants from "../constants/data";

const FeaturedBook = () => {
    return (
        <div className="featured">
            <section className="image">
                
                <h1>The Emblem of the Sea</h1>
                <a href={constants.retailers} target="_blank" className="featured-book">
                    <img src="../images/the-emblem-of-the-sea.png" title="The Emblem of the Sea cover art"/>
                </a>
                <a href={constants.blurb} target="_blank" className="featured-read-more">
                    Read More
                </a>
            </section>
            <section className="description">
                <p className="callout">Available Now!</p>
                <p>
                    <b>Can the forbidden love of two princesses unite their opposing kingdoms?</b>
                </p>
                <p>
                    Deep at the bottom of the ocean, the Emblem of the Sea, rumored to hold the power to rule and protect the underwater realm, waits to be discovered.
                </p>
                <p>
                    The Emblem of the Sea is a tale of two princesses from rival kingdoms who must fight for their love and transcend the deepest of divides in a mesmerizing sapphic underwater retelling of Romeo and Juliet.
                </p>
                <div>
                    <a className="button" href={constants.retailers} target="_blank">
                        See Available Retailers
                    </a>
                </div>
            </section>
        </div>
    );
};

export default FeaturedBook;