import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// styles
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
ReactDOM.render(<App />, document.getElementById("root"));