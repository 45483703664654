// libraries
import React from "react";

/**
 * @function Navigation
 * @returns Markup for navigation elements
 */
const Navigation = () => {
    return (
        <div className="navigation">
            <nobr>
                <a href="/" className="navigation-home">Jenny Made of Stars
                    <img className="stars" src="../images/stars.png" />
                </a>
            </nobr>
        </div>
    );
};

export default Navigation;