// libraries
import React from "react";

// components
import FeaturedBook from "../components/FeaturedBook";
import About from "../components/About";
import Socials from "../components/Socials";
import Navigation from "../components/Navigation"



/**
 * @function Home
 * @returns Markup for the homepage
 */
const Home = () => {
    return (
        <div className="wrapper">
            <Navigation />
            <div className="content">
                <FeaturedBook />
                <About />
            </div>
            <Socials />
            <div className="footer">Designed/Developed by <nobr>Jenny Neal-Teekasingh</nobr></div>
        </div>
    );
};

export default Home;