import React from "react";

// pages
import Home from "./pages/Home";

const App = () =>{
    return (
        <Home />
    )
}

export default App