// libraries
import React from "react";


const Socials = () => {
    return (
        <div className="socials">
            <p>Connect with Jenny:</p>
            <ul>
                <li>
                    <a href="https://www.instagram.com/jennymadeofstars" target="_blank"><span className="icon-instagram" title="Isnstagram"></span></a>
                </li>
                <li>
                    <a href="https://facebook.com/jennymadeofstars" target="_blank"><span className="icon-facebook" title="Facebook"></span></a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@jennymadeofstars" target="_blank"><span className="icon-tiktok" title="TikTok"></span></a>
                </li>
                <li>
                    <a href="https://twitter.com/JennyMadeOfStar" target="_blank"><span className="icon-twitter" title="Twitter"></span></a>
                </li>
                <li>
                    <a href="https://goodreads.com/JennyMadeOfStars" target="_blank"><span className="icon-goodreads" title="Goodreads"></span></a>
                </li>
            </ul>
            <p> 
                <address>
                    Jenny Neal-Teekasingh<br/>
                    P.O. Box 730<br/>
                    Dryden, NY 13053
                </address>
            </p>
            <p><a href="mailto:jennymadeofstars@gmail.com">jennymadeofstars@gmail.com</a></p>
        </div>
    );
};

export default Socials;